import React from "react";
import uiUseStore from "store/UIStore";
import { Section } from "types/ProviderDetails";

interface QnaSectionContentProps {
  data: Section;
}

const QnaSectionContent: React.FC<QnaSectionContentProps> = ({ data }) => {
  const { activeSubsectionId, setActiveSubsectionId } = uiUseStore();

  return (
    <div className="flex xs:flex-col md:flex-row mt-6">
      {/* Vertical Tabs for Subsections */}
      <div className="xs:w-full md:w-1/4">
        <div
          className="flex xs:flex-row md:flex-col 
        rounded-lg xs:overflow-auto scrollbar-hide overflow-hidden
      border-gray-200 dark:border-none"
        >
          {data?.subSections.map((subsection) => (
            <button
              key={subsection.id}
              onClick={() => setActiveSubsectionId(subsection.id)}
              className={`p-4 text-left text-[0.85rem] rounded-lg whitespace-nowrap ${
                activeSubsectionId === subsection.id
                  ? "text-purple-700 bg-gray-100 dark:text-white dark:bg-gray-800"
                  : "text-gray-600 hover:bg-gray-50 dark:text-gray-400"
              }`}
            >
              {subsection.subSectionName.match(
                /(Features|Information|Overview|Services)/i
              )
                ? subsection.subSectionName.split(" ")[0]
                : subsection.subSectionName}
            </button>
          ))}
        </div>
      </div>

      {/* Subsection Content */}
      <div className="xs:w-full md:w-3/4 xs:pl-0 xs:pt-3 md:pl-6 md:pt-0 overflow-auto h-[500px] scrollbar-hide">
        {data?.subSections
          .filter((subSection) => subSection.id === activeSubsectionId)
          .map((subsection) => (
            <div key={subsection.id}>
              {subsection.questions.map((question) => (
                <div
                  key={question.id}
                  className="mb-4 bg-gray-100 p-4 rounded-lg dark:bg-gray-800"
                >
                  <p className="font-medium text-[0.85rem]">
                    {question.questionText.replace(":", "")}
                  </p>
                  <ul className="list-disc pl-5 mt-2">
                    {question.responses.length > 0 ? (
                      question.responses.map((response) => (
                        <li
                          key={response.id}
                          className="text-gray-700 text-[0.85rem] dark:text-gray-400"
                        >
                          {response.responseText}
                        </li>
                      ))
                    ) : (
                      <li className="text-gray-500 text-[0.85rem] dark:text-gray-400">
                        No responses available
                      </li>
                    )}
                  </ul>
                </div>
              ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default QnaSectionContent;
