import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Label from "shared/Label/Label";
import Textarea from "shared/Textarea/Textarea";
import React, { useRef, useState } from "react";
import { ContactTypes } from "types/ContactTypes";
import { Services } from "services/service";
import toast from "react-hot-toast";
import appStore from "store/AppStore";
import Checkbox from "shared/Checkbox/Checkbox";
import { Config } from "constants/config";

const info = [
  {
    title: "Chat with us",
    desc: "Our Friendly team is here to help",
    content: Config.KEY.EMAIL
  }
];

const PageContact = () => {
  const fullNameInputRef = useRef<HTMLInputElement>(null);
  const EmailInputRef = useRef<HTMLInputElement>(null);
  const messageInputRef = useRef<HTMLTextAreaElement>(null);
  const { loading, setLoader } = appStore();
  const [isPrivacy, setIsPrivacy] = useState(false);

  const handleSendMessage = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    let ContactMessage: ContactTypes = {
      fullName: fullNameInputRef.current?.value ?? "",
      email: EmailInputRef.current?.value ?? "",
      message: messageInputRef.current?.value ?? ""
    };
    let result = await Services.CreateContact(ContactMessage);

    try {
      if (result.status.toLowerCase() === "success" || result.data.id > 0) {
        setLoader(false);
        toast.success(result.message, { position: "top-right" });
      } else {
        toast.error("Something Went Wrong, Try Again", {
          position: "top-right"
        });
      }
    } catch (ex) {
      setLoader(false);
      toast.error("Something Went Wrong, Try Again", { position: "top-right" });
    }
  };

  return (
    <div className="w-full flex flex-col justify-start items-center">
      {/**Contact Tab */}
      <div className="md:w-7/12 xs:w-full py-16 flex flex-col justify-start items-center gap-16">
        <div className="w-full flex flex-col justify-center items-center gap-2">
          <h2 className="text-center md:text-5xl xs:text-4xl font-semibold text-neutral-900 dark:text-neutral-100">
            Contact
          </h2>
          <p className="text-sm text-neutral-500 dark:text-neutral-400 text-wrap">
            Get in touch and let us know how we can help
          </p>
        </div>
        {/**Contact Fields and its info */}
        <div className="w-full flex md:flex-row xs:flex-col justify-between items-start md:gap-8 xs:gap-8">
          {/**Contact Fields */}
          <form
            className="md:border-r md:border-neutral-200 md:dark:border-neutral-700 xs:border-r-0 md:w-3/4 xs:w-full flex flex-col justify-start items-start gap-8 pl-4 md:pr-10 xs:pr-4"
            onSubmit={handleSendMessage}
          >
            <h4 className="text-left md:text-2xl xs:text-xl font-semibold text-neutral-900 dark:text-neutral-100">
              Submit a Request
            </h4>
            <label className="block w-full">
              <Label>Full Name</Label>
              <Input
                required
                aria-required="true"
                placeholder="Your Name"
                type="text"
                className="mt-1"
                rounded="rounded-lg"
                ref={fullNameInputRef}
              />
            </label>
            <label className="block w-full">
              <Label>Email address</Label>
              <Input
                required
                aria-required="true"
                placeholder="email@gmail.com"
                type="email"
                className="mt-1"
                rounded="rounded-lg"
                ref={EmailInputRef}
                onInvalid={(e) => {
                  const target = e.target as HTMLInputElement;
                  if (!target.value) {
                    target.setCustomValidity(
                      "Please fill out the email address"
                    );
                  } else {
                    target.setCustomValidity(
                      "Please include a valid email address"
                    );
                  }
                }}
                onInput={(e) => {
                  (e.target as HTMLInputElement).setCustomValidity("");
                }}
              />
            </label>
            <label className="block w-full">
              <Label>Message</Label>
              <Textarea
                required
                aria-required="true"
                className="mt-1 rounded-lg"
                rows={6}
                ref={messageInputRef}
              />
            </label>
            <div className="flex items-center justify-start gap-2">
              <Checkbox
                label=""
                name="privacyPolicyCheckBox"
                checkboxHeight="h-4"
                checkboxWidth="w-4"
                checked={isPrivacy}
                onChange={(checked) => setIsPrivacy(checked)}
              />
              <p className="text-sm text-neutral-500 dark:text-neutral-400 text-wrap">
                I consent to the storage of my data according to the{" "}
                <span className="text-primary-700 font-bold cursor-pointer">
                  Privacy Policy
                </span>
              </p>
            </div>
            <ButtonPrimary translate="rounded-md" disabled={!isPrivacy} loading={loading} className="w-full" type="submit">
              Send Message
            </ButtonPrimary>
          </form>
          {/**Contact Info */}
          <div className="md:w-1/4 xs:w-full flex md:flex-row xs:flex-col justify-start items-start md:gap-0 xs:gap-8 md:py-8 xs:py-4 px-4">
            {info.map((e, i) => (
              <div
                key={i}
                className="w-full flex flex-col justify-start items-start gap-2"
              >
                <p className="text-xl font-semibold text-neutral-900 dark:text-neutral-100">
                  {e.title}
                </p>
                <div className="w-full flex flex-col justify-start items-start">
                  <p className="text-sm text-neutral-500 dark:text-neutral-400 text-wrap">
                    {e.desc}
                  </p>
                  <p className="text-sm text-neutral-950 dark:text-neutral-100">
                    {e.content}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageContact;
