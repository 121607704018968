import { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import Slider from "rc-slider";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import Checkbox from "shared/Checkbox/Checkbox";
import StartRating from "components/StartRating/StartRating";
import { MarkerPin } from "types/MarkerPin";
import appStore from "store/AppStore";
import { APP_CONSTANTS } from "constants/AppConstants";
import { ProviderDetails, Providers } from "types/ProviderDetails";
import { XMarkIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import uiUseStore from "store/UIStore";

type SortKey = keyof Providers;

const TabFilters = ({className = ""}) => {
  const [rangeRatings, setRangeRatings] = useState([0, 5]);
  const [sortBy, setSortBy] = useState<string>("");
  const [isDistanceActive, setIsDistanceActive] = useState(false);
  const [isRecommendedActive, setIsRecommendedActive] = useState(false);
  const [isRatingsActive, setIsRatingsActive] = useState(false);
  

  useEffect(() => {
    handleChange();
  }, [
    sortBy,
    isDistanceActive,
    isRecommendedActive,
    rangeRatings,
    isRatingsActive
  ]);

  const { originalSet, setFilteredPaginatedList, setProvidersList } = appStore();
  

  const handleChange = (isReset?: boolean) => {
    let setBeforeFilter = originalSet ?? [];

    // Apply rating range filter
    const [minRating, maxRating] = isReset ? [0, 5] : rangeRatings;
    setBeforeFilter = setBeforeFilter.filter((marker) => {
      const rating = marker.rating?.overall ?? 0;
      return rating >= minRating && rating <= maxRating;
    });

    // Apply sort by rating filter
    if (isRatingsActive) {
      setBeforeFilter.sort(sortByValues("rating", "desc"));
    }
    // Apply sorting based on active states
    if (isDistanceActive) {
      setBeforeFilter.sort(sortByValues("distanceInMiles", "asc"));
    }
    if (isRecommendedActive) {
      setBeforeFilter.sort(sortByValues("isPreffered", "desc"));
    }

    setBeforeFilter.sort(sortByValues("isSponsored", "desc"));

    // Update the filtered list
    setFilteredPaginatedList(
      setBeforeFilter.slice(0, APP_CONSTANTS.PAGINATION_LIMIT)
    );
    setProvidersList(setBeforeFilter);
  };

  const sortByValues = (key: SortKey, direction: "asc" | "desc") => {
    return (a: Providers, b: Providers): number => {
      let valueA: any;
      let valueB: any;

      // Check if the key exists and get values from providerDetails
      if (a && b && key in a && key in b) {
        if (key === "rating") {
          valueA = a[key]?.overall;
          valueB = b[key]?.overall;
        } else {
          valueA = a[key];
          valueB = b[key];
        }
      }

      // Handle sorting for boolean values specifically for 'IsPreffered' || 'IsSponsored'
      if (key === "isPreffered" || key === "isSponsored") {
        // Convert boolean to number for sorting: true (preferred) > false (not preferred or isSponsored)
        valueA = valueA ? 1 : 0;
        valueB = valueB ? 1 : 0;
      }

      // Default numeric and string sorting
      if (typeof valueA === "number" && typeof valueB === "number") {
        return direction === "asc" ? valueA - valueB : valueB - valueA;
      }

      // Handle any potential fallback or default scenario
      return 0;
    };
  };

  const SortByOptions = (key: string) => {
    if (key === "distance") {
      setIsDistanceActive((prevState) => !prevState); // Toggle the active state
    }
    if (key === "recomended") {
      setIsRecommendedActive((prevState) => !prevState); // Toggle the active state
    }
    if (key === "ratings") {
      setIsRatingsActive((prevState) => !prevState); // Toggle the active state
    }
    if (key === "reset") {
      setIsRecommendedActive(false); // Toggle the active state
      setIsDistanceActive(false);
      setIsRatingsActive(false);
      setRangeRatings([0, 5]);
    }
  };

  const resetRatingFilter = () => {
    setRangeRatings([0, 5]); // Reset to default range
  };

  const resetFilter = () => {
    SortByOptions("reset");
    handleChange(true);
  };

  const renderClearFilters = () => {
    return (
      <div
        onClick={() => {
          resetFilter();
        }}
        className="cursor-pointer flex items-center justify-center px-3 py-1 text-sm rounded-full border border-red-300 dark:border-red-700 hover:border-red-400 dark:hover:border-red-500 focus:outline-none"
      >
        <XMarkIcon className="h-5 text-red-400" />
        <span className="text-red-400">
          Clear<p className="xs:hidden"> Filters</p>
        </span>
      </div>
    );
  };

  const renderSort = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 dark:hover:border-neutral-500 focus:outline-none ${open ? "!border-primary-500 " : ""
                }`}
            >
              <span>Sort</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen md:max-w-sm xs:max-w-xs md:px-4 xs:px-0 xs:-ml-40 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <div key={"recomended"} className="">
                      <Checkbox
                        name={"recomended"}
                        label={"Recommeded"}
                        subLabel={"See all the Recommeded providers first"}
                        onChange={(e) => {
                          SortByOptions("recomended");
                        }}
                        defaultChecked={isRecommendedActive}
                        checked={isRecommendedActive}
                      />
                    </div>
                    <div key={"distance"} className="">
                      <Checkbox
                        name={"distance"}
                        label={"Distance"}
                        subLabel={"See all the near by providers"}
                        onChange={(e) => {
                          SortByOptions("distance");
                        }}
                        defaultChecked={isDistanceActive}
                        checked={isDistanceActive}
                      />
                    </div>
                    <div key={"ratings"} className="">
                      <Checkbox
                        name={"ratings"}
                        label={"Ratings"}
                        subLabel={"See all the most rated providers first"}
                        onChange={(e) => {
                          SortByOptions("ratings");
                        }}
                        defaultChecked={isRatingsActive}
                        checked={isRatingsActive}
                      />
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird onClick={close} sizeClass="px-4 py-2 sm:px-5">
                      Close
                    </ButtonThird>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderRatingFilter = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 dark:hover:border-neutral-500 focus:outline-none ${open ? "!border-primary-500 " : ""
                }`}
            >
              <span className="flex justify-evenly">
                <span className="ml-2 mr-2 xs:ml-0 xs:mr-0">CMS Rating</span>
              </span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen xs:max-w-xs md:max-w-sm md:px-4 xs:px-0 mt-3 left-0 sm:px-0 ">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-8">
                    <div className="space-y-5">
                      <span className="font-medium">
                        Select CMS rating range
                      </span>
                      <Slider
                        range
                        className="text-red-400"
                        min={0}
                        max={5}
                        defaultValue={[rangeRatings[0], rangeRatings[1]]}
                        allowCross={false}
                        onChange={(e) => setRangeRatings(e as number[])}
                      />
                    </div>
                    <span className="flex justify-evenly">
                      min
                      <StartRating
                        reviewCount={5}
                        point={rangeRatings[0]}
                        isSelector={false}
                      />
                      <span className="ml-2 mr-2">-</span>
                      max
                      <StartRating
                        reviewCount={5}
                        point={rangeRatings[1]}
                        isSelector={false}
                      />{" "}
                    </span>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird onClick={close} sizeClass="px-4 py-2 sm:px-5">
                      Close
                    </ButtonThird>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  return (
    <div className={`flex lg:space-x-4 ${className}`}>
      <div className="xs:flex space-x-4 xs:grid-cols-2">
        {renderRatingFilter()}
        {renderSort()}
        {(isDistanceActive ||
          isRatingsActive ||
          isRecommendedActive ||
          !(rangeRatings[0] === 0 && rangeRatings[1] === 5)) &&
          renderClearFilters()}
      </div>
    </div>
  );
};

export default TabFilters;
