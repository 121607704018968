import React, { useRef, useState } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import uiUseStore from "store/UIStore";
import { BlobProvider } from "@react-pdf/renderer";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ReportTemplate from "shared/PDFTemplate/ReportTemplate";
import { currentDateTimeVal } from "utils/dateTime";
import appStore from "store/AppStore";
import { Services } from "services/service";

const EmailProviders = () => {
  const emailInputRef = useRef<HTMLInputElement>(null);
  const FirstNameInputRef = useRef<HTMLInputElement>(null);
  const LastNameInputRef = useRef<HTMLInputElement>(null);
  const emailSendRef = useRef<HTMLFormElement>(null);

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const {
    setEmailDialogOpen,
    isEmailSendClicked,
    CareType,
    setIsEmailSendClicked,
    setIsEmailSuccessDialogOpen
  } = uiUseStore();
  const { saveProfileDetails, tenantConfig, setLoader } = appStore();

  const handleClose = () => {
    setEmailDialogOpen(false);
  };

  const sendEmail = async (blob: Blob) => {
    await Services.SendEmail(blob, email, firstName, lastName);
    setLoader(false);
    setIsEmailSendClicked(false);
    setEmailDialogOpen(false);
    setIsEmailSuccessDialogOpen(true);
  };

  const onEmailSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      emailInputRef.current?.checkValidity() &&
      FirstNameInputRef.current?.checkValidity()
    ) {
      setIsEmailSendClicked(true);
      setEmail(emailInputRef.current.value);
      setFirstName(FirstNameInputRef.current.value);
      setLastName(LastNameInputRef.current?.value ?? "");
    }
  };

  return (
    <div className="flex flex-col text-neutral-700 dark:text-neutral-300 justify-center items-start gap-y-6 px-6 pt-6 pb-5">
      <p className="text-base font-normal text-left">
        You can send the saved providers to recipients. Please enter your name
        and the email address
      </p>
      <form
        className="w-full flex flex-col justify-center items-center gap-y-6"
        ref={emailSendRef}
        onSubmit={onEmailSubmit}
      >
        <div className="w-full flex items-center justify-between gap-5">
          <div className="flex-1">
            <span className="font-semibold text-neutral-800 dark:text-neutral-200">
              First Name
            </span>
            <Input
              type="text"
              required
              aria-required="true"
              placeholder="First Name"
              className={`mt-2`}
              rounded="rounded-lg"
              ref={FirstNameInputRef}
              onInvalid={(e) => {
                const target = e.target as HTMLInputElement;
                if (!target.value) {
                  target.setCustomValidity("Please fill out the First Name");
                }
              }}
              onInput={(e) => {
                (e.target as HTMLInputElement).setCustomValidity("");
              }}
            />
          </div>
          <div className="flex-1">
            <span className="font-semibold text-neutral-800 dark:text-neutral-200">
              Last Name
            </span>
            <Input
              type="text"
              placeholder="Last Name"
              className={`mt-2`}
              rounded="rounded-lg"
              ref={LastNameInputRef}
              onInput={(e) => {
                (e.target as HTMLInputElement).setCustomValidity("");
              }}
            />
          </div>
        </div>
        <div className="w-full">
          <span className="font-semibold text-neutral-800 dark:text-neutral-200">
            Email address
          </span>
          <Input
            type="email"
            required
            aria-required="true"
            placeholder="example@gmail.com"
            className={`mt-2`}
            rounded="rounded-lg"
            ref={emailInputRef}
            onInvalid={(e) => {
              const target = e.target as HTMLInputElement;
              if (!target.value) {
                target.setCustomValidity("Please fill out the email address");
              } else {
                target.setCustomValidity(
                  "Please include a valid email address"
                );
              }
            }}
            onInput={(e) => {
              (e.target as HTMLInputElement).setCustomValidity("");
            }}
          />
        </div>
        <div className="w-full flex flex-row justify-between items-center">
          <ButtonSecondary onClick={handleClose}>Cancel</ButtonSecondary>
          {isEmailSendClicked ? (
            <BlobProvider
              document={
                <ReportTemplate
                  currentDateTime={currentDateTimeVal()}
                  savedProviderDetails={saveProfileDetails}
                  logo={tenantConfig.logo.full}
                  caretype={CareType ?? ""}
                />
              }
            >
              {({ blob, url, loading, error }) => {
                if (loading) return "sending...";
                if (error) return "Error loading document";
                if (blob) {
                  if (isEmailSendClicked) {
                    sendEmail(blob);
                  }
                }
              }}
            </BlobProvider>
          ) : (
            <ButtonPrimary type="submit" className="rounded p-1">
              Send
            </ButtonPrimary>
          )}
        </div>
      </form>
    </div>
  );
};

export default EmailProviders;
