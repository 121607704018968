import { yupResolver } from "@hookform/resolvers/yup";
import FormError from "components/FormError/FormError";
import { KEYS } from "constants/KeyConstants";
import { FC, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Services } from "services/service";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import appStore from "store/AppStore";
import uiUseStore from "store/UIStore";
import useCookie from "utils/useCookies";
import * as yup from "yup";
import { ConfirmationPopup } from "./ConfirmationPopup";
import ButtonClose from "shared/ButtonClose/ButtonClose";

interface FormData {
    email: string;
}

const schema = yup.object().shape({
    email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required")
});

export interface ForgotPasswordProps {
    className?: string;
    callback: () => void;
}

const ForgotPassword: FC<ForgotPasswordProps> = ({ className = "", callback }) => {

    const { loading, setLoader } = appStore();
    const { setCookie } = useCookie();
    const { showConfirmationPopup, setShowConfirmationPopup, setShowLogin } = uiUseStore();

    useEffect(() => {
        setShowConfirmationPopup(false)
    }, []);

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<FormData>({
        resolver: yupResolver(schema)
    });

    const onSubmit: SubmitHandler<FormData> = async (data) => {
        try {
            setLoader(true);
            await Services.ForgotPassword(data.email);

        } finally {
            setCookie(KEYS.EMAIL, data.email, 20);
            setShowConfirmationPopup(true);
            setLoader(false);
        }
    };

    return <div className="w-full my-20 xs:my-5 md:w-1/2 p-8 text-center align-middle justify-center">

        {
            showConfirmationPopup ? <ConfirmationPopup  callback={callback}/> :
                <>
                    <div className="justify-center mb-14">
                        <h3 className="text-[1.75rem] font-medium text-left py-[11px]">Forgot password ✌️</h3>
                        <h4 className="font-normal text-left text-[0.9rem] text-gray-500">Please verify your email address</h4>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className="w-full mt-8">
                        {/* <h4 className="font-thin text-left text-[0.9rem] text-gray-500">Please enter your email address to send the verification link</h4> */}
                        <div className="text-left">
                            <label className="text-neutral-800 dark:text-neutral-200 text-[0.85rem] ml-1 font-medium">Email</label>
                            <input
                                {...register("email")}
                                type="text"
                                placeholder="Enter your email address"
                                className={`mt-1 block w-full h-11 px-4 py-2 text-sm rounded-md border ${errors.email ? 'border-red-500' : 'border-gray-300'} focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
                            />
                            <FormError message={errors.email?.message} />
                        </div>
                        <ButtonPrimary translate="rounded-md" loading={loading} className="w-full mt-3" type="submit">
                            Send verification link
                        </ButtonPrimary>
                        <div className="text-center text-[0.85rem] text-primary-500 hover:underline cursor-pointer font-normal mt-4" onClick={() => { callback() }} >Back to login</div>
                    </form>
                </>
        }
        <span className="absolute right-3 top-3">
            <ButtonClose
                sizes='w-6 h-6'
                className='cursor-pointer'
                onClick={() => {
                    setShowLogin(false);
                    setShowConfirmationPopup(false);
                }}
            />
        </span>

    </div>;
};
export default ForgotPassword;