import { Popover, Transition } from "@headlessui/react";
import {
  UserCircleIcon,
  ArrowRightOnRectangleIcon,
  LifebuoyIcon,
  PowerIcon,
  UserIcon
} from "@heroicons/react/24/outline";
import { HeartIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import appStore from "store/AppStore";
import uiUseStore from "store/UIStore";

export default function AvatarDropdown({
  logout,
  imgUrl,
  username
}: {
  logout: () => void;
  imgUrl: string;
  username?: string;
}) {
  const { isHomePage } = uiUseStore();
  const { profileImage, userDetail } = appStore();
  const navigate = useNavigate();

  const onAccountClicked = () => {
    navigate("/account");
  };

  const actions = [
    {
      name: "Mode",
      href: "#",
      icon: UserCircleIcon,
      onclick: onAccountClicked
    }
  ];

  const solutions = [
    // {
    //   name: "Profile",
    //   href: "#",
    //   icon: UserCircleIcon,
    //   onclick: onAccountClicked
    // },
    // {
    //   name: "Saved Provider",
    //   href: "#",
    //   icon: HeartIcon,
    // },
    {
      name: "Help",
      href: "##",
      icon: LifebuoyIcon
    },

    {
      name: "Logout",
      href: "##",
      icon: PowerIcon,
      onclick: logout
    }
  ];

  return (
    <div className="AvatarDropdown ">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`hover:border-primary-500 border rounded-3xl inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 ${!isHomePage ? "xs:hidden md:block" : ""
                }`}
            >
              <Avatar imgUrl={imgUrl} userName={username} sizeClass="h-11 w-11" textSize="text-lg" />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-[260px] px-4 mt-4 -right-10 sm:right-0 sm:px-0">
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                    <div className=" cursor-pointer flex items-center px-2 py-1 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                      <div className="ml-4">
                        <p className="text-sm font-semibold">{userDetail.firstName} {userDetail.lastName}</p>
                        <p className="text-[0.7rem] font-medium text-neutral-500">{userDetail.email}</p>
                      </div>
                    </div>
                    <div className="border-b border-neutral-200 dark:border-neutral-700 w-full"></div>
                    <div
                      onClick={() => {
                        close()
                        onAccountClicked();
                      }}
                      className=" cursor-pointer flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                    >
                      <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                        <UserIcon aria-hidden="true" className="w-6 h-6" />
                      </div>
                      <div className="ml-4">
                        <p className="text-sm font-medium">Account</p>
                      </div>
                    </div>

                    <div
                      className=" cursor-pointer flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                    >
                      <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                        <HeartIcon aria-hidden="true" className="w-6 h-6" />
                      </div>
                      <div className="ml-4">
                        <p className="text-sm font-medium">Wishlist</p>
                      </div>
                    </div>
                    <div className="border-b border-neutral-200 dark:border-neutral-700 w-full"></div>
                    <SwitchDarkMode />

                    {solutions.map((item, index) => (
                      <div
                        key={index}
                        // to={item.href}
                        onClick={item.onclick}
                        className=" cursor-pointer flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                          <item.icon aria-hidden="true" className="w-6 h-6" />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium ">{item.name}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
