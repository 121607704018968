import { HeartIcon } from "@heroicons/react/24/solid";
import React, { FC, useState } from "react";

export interface BtnLikeIconProps {
  className?: string;
  colorClass?: string;
  isLiked?: boolean;
  onClick?: () => void;
}

const BtnLikeIcon: FC<BtnLikeIconProps> = ({
  className = "",
  colorClass = "text-white bg-black bg-opacity-30 hover:bg-opacity-50",
  isLiked = false,
  onClick = () => { },
}) => {
  const [likedState, setLikedState] = useState(isLiked);

  return (
    <div
      className={`nc-BtnLikeIcon w-8 h-8 flex items-center justify-center rounded-full cursor-pointer ${colorClass} ${className}`}
      data-nc-id="BtnLikeIcon"
      title="Save"
      onClick={onClick}
    >

      <HeartIcon className={`w-5 h-5 ${likedState ? "currentColor" : "none"}`} aria-hidden="true" onClick={onClick} />
    </div>
  );
};

export default BtnLikeIcon;
