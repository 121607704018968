import { yupResolver } from "@hookform/resolvers/yup";
import { KEYS } from "constants/KeyConstants";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Services } from "services/service";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import appStore from "store/AppStore";
import * as yup from "yup";
import care from "images/banners/care.png";
import { useNavigate } from "react-router-dom";
import uiUseStore from "store/UIStore";
import FormError from "components/FormError/FormError";

interface FormData {
    password: string;
    confirmPassword: string;
}

const schema = yup.object().shape({
    password: yup
        .string()
        .min(8, "Password must be at least 8 characters")
        .required("Password is required"),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), undefined] as const, "Passwords must match") // Explicitly typing the array
        .required("Confirm Password is required"),
});


const PageResetPassword = () => {
    const { loading, setLoader } = appStore();
    const { setIsLoginPage } = uiUseStore();

    const navigate = useNavigate();

    // Extract token from the URL search params
    const token = new URLSearchParams(window.location.search).get('token');
    console.log(token);

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<FormData>({
        resolver: yupResolver(schema)
    });

    const onSubmit: SubmitHandler<FormData> = async (data) => {
        try {
            setLoader(true);
            if(token) {
                let result = await Services.ResetPassword(data.password, token);
                if (result) {
                    setIsLoginPage(true);
                    toast.success("Password reset successfully");
                    navigate("/");                    
                }
            }
            else {
                toast.error("The link is invalid.. Please try verification again");
            }
        } catch (error) {
            toast.error("Error in Login");
        } finally {
            setLoader(false);
        }
    };

    return (
        <div className={`nc-PageReset w-full rounded-xl`}>
            <div className="max-h-3/4 p-2 w-full rounded-lg  flex flex-col md:flex-row">
                {/* Left Side */}
                <div className="hidden md:flex  bg-gray-100 rounded-l-lg flex-col justify-center">

                    <div className="relative h-full w-full rounded-lg overflow-hidden">
                        <img src={care} className="object-cover h-full w-full" alt="Healthcare Image" />
                        <div className="absolute inset-0 bg-black bg-opacity-20"></div>
                    </div>

                </div>

                {/* Right Side */}
                <div className="w-full my-20 xs:my-5 p-8 text-center align-middle justify-center">
                    <div className="justify-center mb-8">
                        <h3 className="text-[1.75rem] font-medium text-left py-[11px]">You're Verified 🎉</h3>
                        <h4 className="font-normal text-left text-[0.9rem] text-gray-500">Your email address is verified successfully</h4>
                    </div>
                    {/* <h3 className="text-2xl font-bold mb-4">Get started</h3> */}
                    <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-3 mt-5">

                        <div className="text-left">
                            <label className="text-neutral-800 dark:text-neutral-200 text-[0.85rem] ml-1 font-medium">Password</label>
                            <input
                                {...register("password")}
                                type="password"
                                placeholder="Enter the password"
                                className={`mt-1 block w-full h-11 px-4 py-2 text-sm rounded-md border ${errors.password ? 'border-red-500' : 'border-gray-300'} focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
                            />
                            <FormError message={errors.password?.message} />
                        </div>

                        <div className="text-left">
                            <label className="text-neutral-800 dark:text-neutral-200 text-[0.85rem] ml-1 font-medium">Confirm password</label>
                            <input
                                {...register("confirmPassword")}
                                type="password"
                                placeholder="Confirm your password"
                                className={`mt-1 block w-full h-11 px-4 py-2 text-sm rounded-md border ${errors.password ? 'border-red-500' : 'border-gray-300'} focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
                            />
                            <FormError message={errors.confirmPassword?.message} />
                        </div>

                        <ButtonPrimary translate="rounded-md" loading={loading} className="w-full" type="submit">
                            Reset your password
                        </ButtonPrimary>
                    </form>
                    
                </div>

            </div>
        </div>
    );
};

export default PageResetPassword;