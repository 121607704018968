import React, { FC } from "react";
import imagePng from "images/app/banner.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import appStore from "store/AppStore";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import uiUseStore from "store/UIStore";
import { TypeAnimation } from 'react-type-animation';

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  const { tenantConfig, loading } = appStore();
  const { setShowHeroMobileSearch } = uiUseStore();

  const heroSearchFormMobile = () => {
    setShowHeroMobileSearch(true);
  };

  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 xs:pb-4 lg:pb-96 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="font-medium text-4xl xs:pt-8 md:text-5xl xl:text-7xl !leading-[114%] opacity-0 animate-fade-in">
            Spot your
          </h2>
          <TypeAnimation
            sequence={[
              'Care',
              2000,
              // Same substring at the start will only be typed out once, initially
              'Skilled Nursing',
              1000, // wait 1s before replacing "Mice" with "Hamsters"
              'Pharmacies',
              1000,
              'Hospitals',
              1000,
              'Home Health',
              1000,
              'Adult Day Care',
              1000,
              'Assisted Living',
              1000,
              'Transportation',
              1000,
              'Homeless Care',
              1000,
            ]}
            wrapper="h2"
            speed={50}
            style={{  display: 'inline-block' }}
            repeat={Infinity} 
            className="font-medium text-base xs:text-3xl md:text-5xl xl:text-7xl !leading-[114%] opacity-0 animate-fade-in"
          />
        </div>
        <ButtonPrimary loading={loading} className="mt-2 mb-6 md:hidden" onClick={heroSearchFormMobile}>Start your search</ButtonPrimary>
        <div className="flex-grow overflow-hidden">
          <img className="w-4/5 xs:w-full  rounded-3xl" src={imagePng} alt="hero" />
        </div>
        
      </div>


      <div className="hidden lg:block z-10 mb-12 lg:-mt-80 w-full">
        <HeroSearchForm />
      </div>
    </div>
  );
};

export default SectionHero;
