import Notifier from "components/Notifier/Notifier";
import { useState, useRef, useEffect, FormEvent, KeyboardEvent } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { NotifierModel } from "types/NotifierModel";

interface OTPInputProps {
  onSubmit: (otp: string) => void;
  onResendClick?: () => void;
  loading: boolean;
  errorDetails: string;
  notifierMode: "error" | "success" | "warning" ;
  email: string;
}

const OTPInput: React.FC<OTPInputProps> = ({ onSubmit, loading, onResendClick, errorDetails, email, notifierMode }) => {
  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [timer, setTimer] = useState<number>(60); // Initial timer value (60 seconds)
  const [resendEnabled, setResendEnabled] = useState<boolean>(false);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (timer > 0) {
      setResendEnabled(false);
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else {
      setResendEnabled(true);
      if (interval) clearInterval(interval);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [timer]);

  const handleChange = (value: string, index: number) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to the next input if there’s a value and it's not the last box
    if (value && index < 3) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSubmit(otp.join(""));
  };

  const handleResend = () => {
    if (onResendClick) {
      onResendClick();
    }
    setTimer(60); // Reset the timer
  };

  return (
    <div className="nc-OTPInput lg:min-w-96 my-20 max-w-max" data-nc-id="OTPInput">
      <div className="justify-center mb-4">
        <h3 className="text-[1.75rem] font-medium text-left py-[11px]">Almost done</h3>
        <h4 className="font-normal text-left text-[0.9rem] text-gray-500">Enter the verification code sent to <span className="font-semibold">{email}</span></h4>
      </div>
      <Notifier notifierState={errorDetails !== ""} message={errorDetails} mode={notifierMode} />
      <form onSubmit={handleSubmit} className="space-y-6 mt-4">
        <div className="flex space-x-6">
          {otp.map((_, index) => (
            <input
              key={index}
              type="text"
              value={otp[index]}
              onChange={(e) => handleChange(e.target.value, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              ref={(el) => (inputRefs.current[index] = el)}
              maxLength={1}
              className={`w-12 h-12 text-center border border-gray-300 rounded-lg focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:bg-neutral-900 text-[1.2rem] font-semibold text-neutral-900 dark:text-neutral-100 ${otp[index] ? "border-primary-300" : "border-gray-300"
                }`}
            />
          ))}
        </div>
        <div className="pt-3">
          <ButtonPrimary translate="rounded-md" loading={loading} className="w-full" type="submit">
            Verify
          </ButtonPrimary>
          <div className="text-[0.85rem] text-gray-500 mt-6 text-center">
            Didn't receive the code?{" "}
            <span
              className={`text-primary-500 hover:underline cursor-pointer ${resendEnabled ? "" : "pointer-events-none opacity-50"
                }`}
              onClick={resendEnabled ? handleResend : undefined}
            >
              Resend <span className="inline-block w-[20px] text-center">{timer > 0 && `(${timer})`}</span>
            </span>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OTPInput;
