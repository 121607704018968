import React, { FC, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import uiUseStore from "store/UIStore";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Services } from "services/service";
import appStore from "store/AppStore";
import toast from "react-hot-toast";
import { KEYS } from "constants/KeyConstants";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import care1 from "images/banners/care1.png";
import care from "images/banners/care4.png";
import logo from "images/app/spot/symbol.png";
import ForgotPassword from "./ForgotPassword";
import FormError from "components/FormError/FormError";
import Notifier from "components/Notifier/Notifier";
import { NotifierModel } from "types/NotifierModel";
import CustomDialog from "components/Dialog/CustomDialog";
import { ConfirmationPopup } from "./ConfirmationPopup";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

export interface PageLoginProps {
  className?: string;
}

interface FormData {
  email: string;
  password: string;
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required")
});

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const {
    setIsLoggedIn,
    setShowLogin,
    setShowSignup,
    setShowConfirmationPopup
  } = uiUseStore();
  const { loading, setLoader, setUserDetail, setProfileImage } = appStore();

  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [NotifierState, setNotifierState] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const [NotifierDetails, setNotifierDetails] = useState<NotifierModel>({
    message: "",
    mode: "error"
  });

  const forgotPasswordClick = () => {
    setIsForgotPassword(true);
  };

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      setNotifierState(false);
      setLoader(true);
      let result = await Services.Login(data.email, data.password);
      if (result) {
        localStorage.setItem(KEYS.ISLOGGEDIN, "true");
        localStorage.setItem(KEYS.TOKEN, result?.token!.toString());
        localStorage.setItem(KEYS.EMAIL, data.email);
        localStorage.setItem(KEYS.PROFILEIMAGE, result?.profilePicture!);
        localStorage.setItem(KEYS.USERNAME, result?.firstName);

        setProfileImage(result?.profilePicture!);

        setShowLogin(false);
        setIsLoggedIn(true);
        setUserDetail(result);
      }
    } catch (error: any) {
      console.log(error);
      setNotifierDetails({ message: error.message, mode: "error" });
      setNotifierState(true);
    } finally {
      setLoader(false);
    }
  };

  const onCreateAccountClick = () => {
    setShowLogin(false);
    setShowSignup(true);
  };

  const handleEvent = () => {
    setIsForgotPassword(false);
  };

  return (
    <div className={`nc-PageLogin rounded-xl flex ${className}`}>
      <div className="w-[70rem] h-[43rem] rounded-lg p-2 flex flex-col md:flex-row">
        {/* Left Side */}
        <div className="hidden md:flex md:w-1/2 bg-gray-100 rounded-l-lg flex-col justify-center">
          <div className="relative h-full w-full rounded-lg overflow-hidden">
            <img
              src={care}
              className="object-cover h-full w-full"
              alt="Healthcare Image"
            />
            <div className="absolute inset-0 bg-black bg-opacity-20"></div>
          </div>
        </div>
        {/* Right Side */}
        {isForgotPassword ? (
          <ForgotPassword callback={handleEvent} />
        ) : (
          <div className="w-full my-20 xs:my-5 md:w-1/2 p-8 text-center align-middle justify-center">
            <div className="justify-center mb-4">
              <h3 className="text-[1.75rem] font-medium text-left py-[11px]">
                Welcome back 👋
              </h3>
              <h4 className="font-normal text-left text-[0.9rem] text-gray-500">
                Please sign in to continue
              </h4>
            </div>
            <Notifier
              notifierState={NotifierState}
              message={NotifierDetails.message}
              mode={NotifierDetails.mode}
            />
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="w-full space-y-1 mt-4"
            >
              <div className="text-left">
                <label className="text-neutral-800 dark:text-neutral-200 text-[0.85rem] ml-1 font-medium">
                  Email
                </label>
                <input
                  {...register("email")}
                  type="text"
                  placeholder="Enter your email address"
                  className={`mt-1 block w-full h-11 px-4 py-2 text-sm rounded-md border ${
                    errors.email ? "border-red-500" : "border-gray-300"
                  } focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
                />
                <FormError message={errors.email?.message} />
              </div>
              <div className="text-left">
                <label className="block text-neutral-800 dark:text-neutral-200 text-[0.85rem] font-medium">
                  Password
                </label>
                <div className="relative">
                  <input
                    {...register("password")}
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    className={`block w-full h-11 px-4 rounded-md border ${
                      errors.password ? "border-red-500" : "border-gray-300"
                    } focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-3 flex items-center text-sm text-neutral-500"
                  >
                    {showPassword ? (
                      <EyeSlashIcon className="w-5 h-5" />
                    ) : (
                      <EyeIcon className="w-5 h-5" />
                    )}
                  </button>
                </div>
                <FormError message={errors.password?.message} />
              </div>
              <div
                className="text-right text-[0.85rem] pb-4 text-primary-500 hover:underline cursor-pointer font-normal"
                onClick={forgotPasswordClick}
              >
                Forgot password?
              </div>
              <ButtonPrimary
                translate="rounded-md"
                loading={loading}
                className="w-full"
                type="submit"
              >
                Login
              </ButtonPrimary>
            </form>
            <div className="text-[0.85rem] text-gray-500 text-center mt-6">
              Don't have account?{" "}
              <span
                className="text-primary-500 hover:underline cursor-pointer font-normal"
                onClick={onCreateAccountClick}
              >
                Sign up
              </span>
            </div>
          </div>
        )}
      </div>
      <span className="absolute right-3 top-3">
        <ButtonClose
          sizes="w-6 h-6"
          className="cursor-pointer"
          onClick={() => {
            setShowLogin(false);
            setIsForgotPassword(false);
          }}
        />
      </span>
    </div>
  );
};

export default PageLogin;
