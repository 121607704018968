import { TotalReview } from "types/ProviderDetails";

import CommentComponent from "components/CommentComponent/CommentComponent";

const ReviewSection = ({ data }: { data: TotalReview }) => {
  const ReviewContentSection = () => {
    return (
      <div className="divide-y divide-neutral-100 dark:divide-neutral-800 w-full">
        {data.review.map((e, index) => {
          if (e.review === null || e.review === "") {
            return null;
          }
          return <CommentComponent data={e} index={index} />;
        })}
      </div>
    );
  };

  return (
    <div className="flex flex-col items-start justify-start gap-5 w-full mt-2">
      {ReviewContentSection()}
    </div>
  );
};

export default ReviewSection;
