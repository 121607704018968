import React from 'react';

interface FormErrorProps {
  message?: string; // Optional to handle cases where the message is undefined
}

const FormError: React.FC<FormErrorProps> = ({ message }) => {
  if (!message) return <p className="text-red-500 text-xs mt-[0.35rem]">&nbsp;</p>; // Return null instead of an empty string

  return <p className="text-red-500 text-xs ml-1 mt-[0.35rem]">{message}</p>;
};

export default FormError;
