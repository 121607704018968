import React, { FC, useEffect, useState, useRef } from "react";
import appStore from "store/AppStore";
import { ProviderCard } from "./ProviderCard";
import Pagination from "shared/Pagination/Pagination";
import uiUseStore from "store/UIStore";
import { GoogleMap } from "components/GoogleMap/GoogleMap";
import Heading2 from "components/Heading/Heading2";
import TabFilters from "./TabFilters";
import { Providers } from "types/ProviderDetails";
import lottie from "lottie-web";
import noRecordsAnimation from "../../images/Lottie/NoRecords.json";
import Heading from "components/Heading/Heading";
import { ChevronUpIcon, FunnelIcon } from "@heroicons/react/24/outline";

export interface PageListProps {}

const PageList: FC<PageListProps> = () => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const NorecordContainerRef = useRef(null);

  const [selectedMarker, setSelectedMarker] = useState<Providers | null>();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const { providersList, filteredPaginatedList } = appStore();
  const { isTabFiltersOpen, setIsTabFiltersOpen } = uiUseStore();
  const { isHomePage, isList, setIsHomePage } = uiUseStore();

  useEffect(() => {
    setIsHomePage(false);
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (filteredPaginatedList && filteredPaginatedList.length === 0) {
      if (NorecordContainerRef.current) {
        const lottieInstance = lottie.loadAnimation({
          container: NorecordContainerRef.current,
          renderer: "svg",
          loop: true,
          autoplay: true,
          animationData: noRecordsAnimation
        });

        return () => {
          lottieInstance.destroy();
        };
      }
    }
  }, [filteredPaginatedList]);

  return (
    <div>
      <div className="relative flex h-[88vh] scrollbar-none">
        <div
          className={`flex- lg:flex-1 h-full w-full overflow-hidden ${
            isList ? "flex" : ""
          } flex-col`}
        >
          
            <div className="flex flex-col gap-y-3 items-start w-full px-6 py-6 xs:py-2">
              <div className="w-full flex xs:flex-col md:flex-row justify-between xs:items-start md:items-center xs:gap-1 md:gap-0">
                {isSmallScreen && isTabFiltersOpen ? (
                  <ChevronUpIcon
                    className="absolute h-5 right-0 mt-2 mr-5"
                    onClick={() => {
                      setIsTabFiltersOpen(false);
                    }}
                  />
                ) : (
                  <FunnelIcon
                    className="absolute h-5 right-0 mt-2 mr-5"
                    onClick={() => {
                      setIsTabFiltersOpen(true);
                    }}
                  />
                )}
                <Heading2 heading={`${providersList.length} Providers`} />
                <Pagination
                  className={`${
                    isSmallScreen && !isTabFiltersOpen && `hidden`
                  }`}
                />
              </div>
              <TabFilters
                className={`${isSmallScreen && !isTabFiltersOpen && `hidden`}`}
              />
            </div>
          

          <div
            className={`w-full h-full overflow-scroll scrollbar-hide px-6 pb-6 responsive-height  flex flex-col gap-y-3`}
          >
            <div className="grid grid-cols-1 gap-8">
              {filteredPaginatedList && filteredPaginatedList.length > 0 ? (
                filteredPaginatedList.map((item) => (
                  <div
                    key={item.code}
                    onMouseEnter={() => {
                      setCurrentHoverID((_) => item.code);
                      setSelectedMarker(item);
                    }}
                    onMouseLeave={() => {
                      setCurrentHoverID((_) => -1);
                      setSelectedMarker((_) => null);
                    }}
                  >
                    <ProviderCard data={item} />
                  </div>
                ))
              ) : (
                <div className="relative flex flex-col h-full max-w-full items-center justify-center">
                  <div
                    className="w-96 h-96 -mt-32"
                    ref={NorecordContainerRef}
                  ></div>
                  <Heading
                    desc={"Please search for other cares"}
                    className="text-center"
                  >
                    No providers found
                  </Heading>
                </div>
              )}
            </div>
            <div className="flex mt-4 justify-center items-center pb-6">
              <Pagination className={`${isHomePage && `hidden`}`} />
            </div>
          </div>
        </div>
        <div
          className={`lg:flex-1 xl:h-full xl:w-full ${
            isList ? "block" : "xs:hidden"
          } xl:block`}
        >
          <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-full rounded-md overflow-hidden">
            <GoogleMap
              selectedId={currentHoverID}
              latLng={{
                lat: selectedMarker?.locations[0].latitude ?? 0,
                lng: selectedMarker?.locations[0].longitude ?? 0
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageList;
