import React, { useEffect, useState } from "react";
import { GoogleMap as GMap, Libraries, useLoadScript } from '@react-google-maps/api';
import appStore from "store/AppStore";
import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
import { darkMap } from "./dark";
import { lightMap } from "./light";
import { Config } from "constants/config";
import GoogleMapReact from "google-map-react";
import { Providers } from "types/ProviderDetails";

interface GoogleMapProps {
  selectedId: string | number;
  latLng?: { lat: number; lng: number };
}


const libs: Libraries = ["places", "marker"]

export const GoogleMapV2: React.FC<GoogleMapProps> = ({ selectedId, latLng }) => {
  const { filteredPaginatedList, theme } = appStore();
  const [map, setMap] = useState<google.maps.Map>();
  const { isLoaded } = useLoadScript({ googleMapsApiKey: Config.KEY.MAP, libraries: libs });

  let markerProps = {
    center: {
      lat: latLng?.lat || 0,
      lng: latLng?.lng || 0
    },
    zoom: 10
  };

  useEffect(() => {
    if (map) {
      map.setOptions({ styles: theme === "dark" ? darkMap : lightMap });
    }
  }, [theme, map]);

  // useEffect(() => {
  //   if (map && latLng?.lat && latLng?.lng) {
  //     map.panTo({ lat: latLng.lat, lng: latLng.lng });
  //   }
  // }, [latLng, selectedId, map]);

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      filteredPaginatedList.forEach((coord: Providers) => {
        bounds.extend(
          new google.maps.LatLng(
            coord.locations[0].latitude,
            coord.locations[0].longitude
          )
        );
      });
      map.fitBounds(bounds);
      const currentZoom = map.getZoom();
      if (currentZoom) {
        map.setZoom(currentZoom - 1); // Decrease the zoom level by 1
      }
      // map.setZoom(markerProps.zoom);
      map.setOptions({
        draggable: true,
        zoomControl: true,
        scrollwheel: false,
        disableDoubleClickZoom: false,
      });
      if (theme) map.setOptions({ styles: theme === "dark" ? darkMap : lightMap });
    }
  }, [filteredPaginatedList, map]);

  if (!isLoaded) {
    return <div>Loading Map...</div>;
  }


  return (
    <GoogleMapReact
      defaultZoom={12}
      defaultCenter={{ lat: 59.95, lng: 30.33 }}
      bootstrapURLKeys={{
        key: Config.KEY.MAP
      }}
      onGoogleApiLoaded={({ map }) => setMap(map)}
      yesIWantToUseGoogleMapApiInternals
    >
      {filteredPaginatedList.map((item) => (
        <AnyReactComponent
          isSelected={selectedId === item.code}
          lat={item.locations[0].latitude}
          lng={item.locations[0].longitude}
          key={item.code}
          item={item}
        />
      ))}
    </GoogleMapReact>

  );
};
