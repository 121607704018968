import care from 'images/banners/care4.png';

export const preCache = async () => {
    const cache = await caches.open("spot_care");
    const urls = [
        care
    ];

    await cache.addAll(urls);
};

export const fetchFromPreCache = async (imageName: string): Promise<Response> => {
    const cache = await caches.open("spot_care");
    const response = await cache.match(imageName);

    if (response) {
        return response;
    } else {
        throw new Error(`Image ${imageName} not found in cache`);
    }
};
