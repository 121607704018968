import React, { useState, useEffect, useRef } from "react";
import { Section, TotalReview } from "types/ProviderDetails";
import ReviewSection from "./ReviewSection";
import QnASectionContent from "./QnASectionContent";
import uiUseStore from "store/UIStore";

const QnASections = ({
  data,
  totalReview
}: {
  data: Section[];
  totalReview: TotalReview;
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  

  const {
    setActiveSubsectionId,
    isReviewSelected,
    setIsReviewSelected,
    activeSection,
    setActiveSection
  } = uiUseStore();

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    setActiveSubsectionId(data[0]?.subSections[0]?.id || 0);
    setActiveSection(data[0]?.id || 0);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSectionClick = (id: number) => {
    setIsReviewSelected(false);
    setActiveSection(id);
    // Reset active subsection when section changes
    const section = data.find((sec) => sec.id === id);
    if (section?.subSections.length) {
      setActiveSubsectionId(section.subSections[0].id);
    }
  };
  const activeSectionData = data.find(
    (section) => section.id === activeSection
  );

  const handleReviewClick = () => {
    setActiveSection(0);
    setIsReviewSelected(true);
  };

  return (
    <div className="pt-6 w-full">
      {/* Horizontal Tabs for Sections */}
      <div className="flex border-b border-gray-200 xs:overflow-auto scrollbar-hide">
        {data.map((section) => (
          <button
            key={section.id}
            onClick={() => handleSectionClick(section.id)}
            className={`mr-6 pb-2 text-[0.85rem] ${
              activeSection === section.id
                ? "text-purple-600 border-b-4 border-purple-700 dark:text-white dark:border-white"
                : "text-gray-600 border-b-4 border-transparent hover:text-gray-800 dark:text-gray-400"
            }`}
          >
            {section.sectionName.split(" ")[0]}
          </button>
        ))}
        {totalReview && (
          <button
            onClick={() => handleReviewClick()}
            className={`mr-6 pb-2 text-[0.85rem] ${
              isReviewSelected
                ? "text-purple-600 border-b-4 border-purple-700 dark:text-white dark:border-white"
                : "text-gray-600 border-b-4 border-transparent  hover:text-gray-800 dark:text-gray-400"
            }`}
          >
            Review
          </button>
        )}
      </div>
      {isReviewSelected ? (
        <ReviewSection data={totalReview} />
      ) : (
        <QnASectionContent data={activeSectionData!} />
      )}
    </div>
  );
};

export default QnASections;
