import Avatar from "shared/Avatar/Avatar";
import { Review } from "types/ProviderDetails";
import StartRating from "components/StartRating/StartRating";
import { useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

const CommentComponent = ({ data, index }: { data: Review; index: number }) => {
  const MAX_LENGTH = 300;

  const [isExpanded, setIsExpanded] = useState(false);

  const getReviewText = (): string => {
    const fullText = data.review ?? "";

    if (!isExpanded && fullText.length > MAX_LENGTH) {
      return fullText.toString().substring(0, MAX_LENGTH) + "...";
    }
    return fullText.toString();
  };

  const handleClick = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div
      key={index}
      className="w-full flex items-start justify-start gap-6 py-4"
    >
      <Avatar
        sizeClass="h-10 w-10 text-lg"
        radius="rounded-full"
        imgUrl={""}
        userName={data.username}
        textSize="text-lg"
      />
      <div className="flex flex-col items-start justify-start w-full gap-4">
        <div className="flex flex-row items-start justify-between w-full">
          <div className="flex flex-col items-start justify-start">
            <p className="text-sm font-semibold">{data.username}</p>
            <p className="text-xs text-neutral-500 dark:text-neutral-400">
              {dayjs(data.reviewPeriod).fromNow()}
            </p>
          </div>
          <StartRating
            reviewCount={5}
            point={parseInt(data.rating) ?? 0}
            isSelector={false}
          />
        </div>
        <p className="text-neutral-6000 dark:text-neutral-300 text-[0.8rem] font-display">
          {getReviewText()}
          {data.review && data.review.length > MAX_LENGTH && (
            <span
              className="text-blue-500 ml-2 cursor-pointer"
              onClick={handleClick}
            >
              {isExpanded ? "Read less" : "Read more"}
            </span>
          )}
        </p>
      </div>
    </div>
  );
};

export default CommentComponent;
